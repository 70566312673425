import React from 'react'
import Hero from './Hero'
import HumanCapital from './HumanCapital'

const AboutHome = () => {
  return (
    <div>
        <Hero/>
        <HumanCapital/>
    </div>
  )
}

export default AboutHome