import React from 'react';

const SaishyaMam = () => {
  return (
    <div style={{ margin: 0, padding: 0, height: '100vh', width: '100vw', overflow: 'hidden' }}>
      <iframe
        src="https://app.powerbi.com/view?r=eyJrIjoiYWFjYzQyM2QtOWIzNS00ODMzLThjMTgtNGUzMTNjZTdmN2EwIiwidCI6IjM0YmQ4YmVkLTJhYzEtNDFhZS05ZjA4LTRlMGEzZjExNzA2YyJ9&pageName=d94d0d00296848009950"
        title="website"
        style={{
          border: 'none',
          height: '100%',
          width: '100%',
        }}
      ></iframe>
    </div>
  );
};

export default SaishyaMam;
